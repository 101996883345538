<template>
  <BaseDialog :showCondition="registerDialogState.show" :persistentCondition="registerDialogState.persistent"
    @closed="changeRegisterDialogState(false)">
    <template v-slot:title>
      <span class="primary--text"> إنشاء حساب </span>
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-col align="center" cols="9">
              <div v-if="registerDialogState.persistent">
                عليك أولاً أن تقوم بالتسجيل ضمن المنصة أو تسجيل الدخول إلى حسابك
              </div>

              <BaseTextField v-model="name" label="الاسم" placeholder="الاسم" name="الاسم"
                :rules="[requiredRule, nameRule]" @keyup.enter="validateThenPostRegister()">
              </BaseTextField>

              <BaseTextField v-model="phoneNumber" label="رقم الهاتف المحمول" placeholder="رقم هاتف محمول مفعل عليه الواتساب" name="رقم الهاتف المحمول"
                type="number" :rules="[requiredRule, phoneNumberRule]" @keyup.enter="validateThenPostRegister()">
              </BaseTextField>

              <BasePasswordField class="mt-3" v-model="password" label="كلمة السر" placeholder="كلمة السر"
                name="كلمة السر" type="password" :rules="[requiredRule, passwordRule]"
                @keyup.enter="validateThenPostRegister()">
              </BasePasswordField>

              <BaseTextField v-model="studyField" label="مجال الدراسة" placeholder="مجال الدراسة" name="مجال الدراسة"
                :rules="[requiredRule, studyFieldRule]" @keyup.enter="validateThenPostRegister()">
              </BaseTextField>
              <v-autocomplete v-model="relatedFieldIds" chips clearable item-value="id" :items="jobFields"
                :filter="customFilter" label="مجالات تهتم بها"
                hint="من أجل تجربة أفضل في عرض فرص العمل مخصصة يمكنك إدخال مجالات تهتم بها" persistent-hint outlined
                multiple :rules="[requiredRule]">
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip>{{ item.name }}</v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-row justify="center">
        <v-col align="center">
          <v-row justify="space-around">
            <v-btn @click="validateThenPostRegister()" class="accent rounded-pill px-10 mt-5">
              إنشاء الحساب
            </v-btn>
            <v-btn text outlined rounded v-if="registerDialogState.persistent && !isWebView"
              @click="cancelAndRedirectToHome()" class="primary--text mt-5">
              العودة للصفحة الرئيسية
            </v-btn>
          </v-row>
          <div class="mt-5">
            <v-btn text @click="showLoginDialog()">
              لديك بالفعل حساب؟
              <span class="accent--text text-decoration-underline">
                سجل الدخول
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../store/AuthStore";
import { useJobOpportunitiesStore } from "@/modules/job_opportunities/store/JobOpportunitiesStore";
import { Notify } from "@/core/classes/notify/Notify";

export default {
  data() {
    return {
      phoneNumber: "",
      name: "",
      password: "",
      studyField: "",
      relatedFieldIds: [],
      persistent: true,
    };
  },
  components: { BaseDialog },
  mounted() {
    if (this.jobFields.length == 0)
      //incase fields have not been fetched yet
      this.fetchJobFields()
        .then()
        .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useAuthStore, ["registerDialogState"]),
    ...mapState(useJobOpportunitiesStore, ["jobFields"]),
    isWebView() {
      return this.$route.query.webView;
    },
  },
  methods: {
    customFilter(field, queryText) {
      const fieldText = field.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return fieldText.indexOf(searchText) > -1;
    },
    ...mapActions(useAuthStore, [
      "changeLoginDialogState",
      "changeRegisterDialogState",
      "register",
    ]),
    ...mapActions(useJobOpportunitiesStore, ["fetchJobFields"]),
    cancelAndRedirectToHome() {
      this.changeRegisterDialogState(false);
      this.$router.push("/");
    },
    showLoginDialog() {
      this.changeRegisterDialogState(false);
      this.changeLoginDialogState(true, this.registerDialogState.persistent);
    },
    validateThenPostRegister() {
      if (this.$refs.form.validate()) {
        var registerData = {
          password: this.password,
          name: this.name,
          username: this.username,
          studyField: this.studyField,
          phoneNumber: this.phoneNumber,
          fieldsIds: this.relatedFieldIds,
        };
        this.register(registerData).then(()=>{
          Notify.showMessage(
          "success",
          " لقد قمنا بإرسال رمز التحقق إلى رقمك عبر الواتساب، قد تحتاج الرسالة عدة دقائق الرجاء الانتظار"
        );
        });

      }
    },
  },
};
</script>

<style scoped></style>
